@import "../Register/Register";

.congrats-form {
  // box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  // background-color: #ef1c26;
  border-radius: 16px;
  // padding: 1em;
  padding-bottom: 1em;
  width: 100%;
  // max-width: 350px;
  margin: 0 auto;
}
.congratulation-container {
  // padding: 1em;
  color: #fff;
}
.referal-code {
  padding: 0.5em 1em 0.5em 3em;
  color: rgba(21, 17, 13, 0.7);
  background-color: #fff;
  border-radius: 4px;
  // border: 1px solid #000;
  margin: 0;
  width: 90%;
}
.back-btn {
}
.share-button {
}
.referral-input {
  display: flex;
  // width: 90%;
  background: #fff;
  align-items: center;
  border: 1px solid #000;
  border-radius: 4px;
}
.copy-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  font-weight: 600;
  padding-right: 1em;
}

.copy-icon {
  width: 16px;
  height: 16px;
}

.congratulation-title {
  font-family: RobotoBold;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  margin: 0;
}
.congratulation-title-sub {
  font-family: LexendDeca;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  margin-top: 0.5em;
}
.congratulation-amt {
  color: #ffef00;
  font-weight: 700;
  margin: 0;
  font-size: 55px;
}
.congratulation-text {
  font-family: FuturuStd;
  font-weight: bolder;
  font-size: 1.2em;
  margin-bottom: 0;
}
.congratulation-text-sub {
  font-family: FuturuStd;
  font-weight: bolder;
  margin: .5em;
  font-size: 1.5em;
}
.congrats-sub-text{
  margin: 0;
  color: #fff;
  // font-weight: bold;
  font-size: 1.8em;
  font-family: RobotoBold;
}
.congratulation-image{
  width: 223px;
  height: 223px;
}

.redeem-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  .redeem-question {
    color: #fff;
    text-align: center;
    // font-family: $Gotham-Black;
    font-family: LexendDeca;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
  }

  .redeem-options {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      display: flex;
      height: 44px;
      padding: 16px 10px;
      justify-content: center;
      align-items: center;
      gap: 186px;
      color: #000;
      text-align: center;
      // font-family: $GothamRnd-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%;
      // border-radius: 52px;
      // background: #091c33;
      outline: none;
      border: none;
      border-radius: 8px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.73);

      &:active {
        background: #f1c40f;
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.29);
      }
    }
  }
}
