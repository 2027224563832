@import "../Register/Register";

.redeem-payment-form {
  // box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  // background-color: #000;
  border-radius: 16px;
  padding: 1em;
  // width: 90%;
  width: 100%;
  max-width: 400px;
}

.payment-title {
  font-family: FuturuStd;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5em;
  .cashback {
    color: #f3b77e;
  }
}
.cashback{
  margin: .5em 0 0 0 ;
  animation: tada 3s infinite;
  // transition-delay: 3s;
}

@keyframes tada {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
}

.redeem-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  .redeem-question {
    color: #fff;
    text-align: center;
    // font-family: $Gotham-Black;
    font-family: FuturuStd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
  }

  .redeem-options {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      display: flex;
      height: 44px;
      padding: 16px 10px;
      justify-content: center;
      align-items: center;
      gap: 186px;
      color: #000;
      text-align: center;
      // font-family: $GothamRnd-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%;
      // border-radius: 52px;
      // background: #091c33;
      outline: none;
      border: none;
      border-radius: 8px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.73);

      &:active {
        background: #f1c40f;
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.29);
      }
    }
  }
}
.group-checkbox {
  margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 15px;
  //not a robot
  display: flex;
  justify-content: center;
}
.pack-container-payment {
  // bottom: 0;
  // position: absolute;
  // height: 75%;
  // border-top-left-radius: 15px;
  // border-top-right-radius: 15px;
  // width: 100%;
  // z-index: 1;
  // padding-top: 3em;
  // background-size: 65%;
  // background-repeat: no-repeat;
  // background-position: bottom center;
  background-color: #ee1b24;
  width: 90%;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkbox-container-upi {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: left;
  color: #fff;
  font-family: FuturuStd;
  // font-style: italic;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    // border: 1px solid #858585;
    // background: #fff;
    background: transparent;
    border: 1.6px solid #f3f2e8;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      border: solid #ffbc1f;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.redeem-form {
  box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  background-color: #ef1c26;
  border-radius: 16px;
  padding: 1em;
  width: 90%;
  max-width: 350px;
}
.cashback-payout-form {
  // width: 90%;
  width: 100%;
  // position: absolute;
  top: 65%;
  left: 50%;
  // transform: translate(-50%, -50%);
  // box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  // background-color: #000;
  border-radius: 16px;
  padding: 1em;
}
.upi-input {
  padding-bottom: 1em;
}
.payment-pack-container-txt {
  // font-family: SpaceGrotesk;
  // font-weight: 800;
  // padding: 1em;
  // color: #8c6629;
  // // padding-top: 5em;
  // margin-top: 0;
  font-family: FuturuStd;
  font-weight: 800;
  padding: 1em;
  color: #8c6629;
  // margin-top: 0;
  margin-top: 0.5em;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.wrapper {
  top: -25%;
  left: 0px;
  position: absolute;
  width: 100vw;
}
.style-section {
  padding-top: 2em;
}
.pack {
  // top: -28%;
  // left: 0px;
  // position: absolute;
  // width: 100vw;
}

.first-pack {
  // top: -28%;
  top: -22%;
  left: 0px;
  position: absolute;
  width: 100vw;
}
.payment-btn-container {
  position: relative;
  bottom: 0;
  // right: 0;
  // margin-bottom: 1.5em;
  // left: 50%;
  left: 50%;
  transform: translateX(-50%);
  // top: 1vh;
}
.open-text {
  border-radius: 32px;
  background: linear-gradient(
    90deg,
    #96181d 0%,
    #a51a20 8%,
    #ba1e25 22%,
    #c62128 36%,
    #cb2229 50%,
    #c62128 64%,
    #ba1e25 78%,
    #a51a20 92%,
    #96181d 100%
  );
  box-shadow: 0px 0px 0px 2px #ffbc1f;
  width: 200px;
  height: 64px;
  color: #fff;
  font-weight: 800;
  // margin-right: 1em;
  padding: 0 0 0 1em;
  border-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1em;
  // @media screen and (min-width: 768px) {
  //   position: fixed;
  // }
  // .btn-container {
  //   display: flex;
  //   justify-content: end;
  //   margin-top: 2em;
  //   padding-right: 1em;
  // }
}

@media screen and (min-width: 768px) {
  .gate1 {
    position: absolute;
    left: 64px;
    img {
      width: 123px;
    }
  }
  .wrapper {
    width: 100%;
  }
}
@media screen and (min-height: 768px) {
  .wrapper {
    width: 100%;
  }
  .pack-container {
    padding-top: 6em;
  }
  .pack,
  .upload-selfie-page img.selected-style-image {
    top: 0%;
  }
  // .upload-selfie-page .color-palette {
  //   top: 3em !important;
  // }
  .welcome-text {
    padding-top: 3em;
  }
}

.payment-payout-option-container {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  //   background: url("../../assets/images/bg/bg4.png"),
  //     #211846 0% 0% / 100px 100px repeat;
  //   background-blend-mode: overlay, normal;
  //   mix-blend-mode: darken;
  //   backdrop-filter: blur(24.5px);
  // margin-bottom: 2em;
  justify-content: space-around;
  border: 1px solid #f3b67d;
  height: 50px;
  .payout-option {
    width: -webkit-fill-available;
    padding: 0.5em;
    font-family: FuturuStd;
    // font-size: 16px;
    font-size: .8em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    color: #261B62;
    width: 50%;
  }

  .payout-option.selected {
    // border-radius: 26px;
    background: #261B62;
    color: #fff;
    width: 50%;
    justify-content: center;
    margin: .5em;
  }
}
.cashback-payout-title {
  color: #fff;
  color: #fff;
  font-size: 1.4em;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: bold;
}

.payment-input-container {
  text-align: center;
  // margin: 0 0 2em 0;
  // background-color: #210d48;
  // border-radius: 16px;
  // padding: 1.5em;
  min-height: 14em;
  padding-top: 1em;

  ::placeholder {
    color: rgba(21, 17, 13, 0.7);
    text-align: center;

    padding-bottom: 0.5em;
  }
  input {
    // background: transparent;
    border: none;
    text-align: center;
    // border-bottom: 1px solid #b7924c;
    border-radius: 10px;
    min-width: 90%;
    max-width: 100%;
  }
}
.error-payment {
  color: #ed1c24;
  text-align: center;
  font-family: FuturuStd;
  font-size: 16px;
}
